import { forwardRef, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { FaPlusCircle, FaExternalLinkAlt, FaExclamationCircle } from 'react-icons/fa';
import moment from 'moment';
import { LightboxImage } from 'react-awesome-lightbox';

import { get, patch, post } from 'webapp-common/api/RestApi';
import PatchFlow from 'webapp-common/models/PatchFlow';
import PatientBasic from 'webapp-common/models/PatientBasic';
import PatchFlowVisit from 'webapp-common/models/PatchFlowVisit';
import PatchFlowVisitType from 'webapp-common/models/PatchFlowVisitType';
import PatchAllergenReading from 'webapp-common/models/PatchAllergenReading';
import PatchReading from 'webapp-common/models/PatchReading';
import PatchAllergenReadingScore, { PATCH_ALLERGEN_READING_SCORES } from 'webapp-common/models/PatchAllergenReadingScore';
import PatchAllergenResult from 'webapp-common/models/PatchAllergenResult';
import PatchAllergenResultScore, { PATCH_ALLERGEN_RESULT_SCORES, getPatchAllergenResultScoreLabel } from 'webapp-common/models/PatchAllergenResultScore';
import PatchResult from 'webapp-common/models/PatchResult';
import { PATCH_ALLERGENS, getPatchAllergenWellNumber, getPatchAllergenName } from 'webapp-common/models/PatchAllergen';

import Loading from '../components/Loading';
import LightboxPhotos from '../components/LightboxPhotos';

import {
  getPatchFlowByIdQueryKey,
  getPatchFlowVisitByIdQueryKey,
  getPatchFlowVisitPhotosByPatchFlowVisitIdQueryKey,
  getPatchFlowVisitsByPatchFlowIdQueryKey,
  getPatientBasicByIdQueryKey,
} from '../utils/query-keys';
import { getSuggestedPatchAllergenResultScore } from '../utils/suggested-patch-result';

interface PatchFlowVisitPhoto {
  filename: string,
  original_uri: string,
  thumbnail_uri: string,
}

const getVisitTypeTitle = (visitType: PatchFlowVisitType) => {
  switch (visitType) {
    case 'placement':
      return 'Patch Placement';
    case 'reading-one':
      return 'Reading #1';
    case 'reading-two':
      return 'Reading #2';
  }
};


const getDateFromFilename = (filename: string): Date | null => {
  const matches = filename.match(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{3})\.jpg$/);
  if (!matches) {
    return null;
  }
  const [year, month, day, hour, minute, seconds, ms] = matches.slice(1);
  const isoString = `${year}-${month}-${day}T${hour}:${minute}:${seconds}.${ms}Z`;
  return new Date(isoString);
  // return moment(date).format('ddd, MMM D @ h:mm:ssa');
}


function VisitPhotos({
  patchFlowVisit,
  openLightbox,
}: {
  patchFlowVisit: PatchFlowVisit,
  openLightbox: (images: LightboxImage[], index: number) => void,
}) {
  const patchFlowVisitId = patchFlowVisit.id;
  const visitTypeTitle = getVisitTypeTitle(patchFlowVisit.visit_type);

  const {
    data: photos,
  } = useQuery(getPatchFlowVisitPhotosByPatchFlowVisitIdQueryKey(patchFlowVisitId), async () => {
    if (!patchFlowVisitId) {
      throw new Error('Missing patch flow visit ID');
    }
    return await get<PatchFlowVisitPhoto[]>(`/patch-flow-visits/${patchFlowVisitId}/photos`);
  }, {
    enabled: Boolean(patchFlowVisit),
    refetchInterval: 30000, // 30 seconds
  });

  const imageData = (photos || []).map((p) => {
    return {
      filename: p.filename,
      originalUrl: p.original_uri,
      thumbnailUrl: p.thumbnail_uri,
      date: getDateFromFilename(p.filename),
    };
  });

  const lightboxImages = imageData.map((im) => {
    const dateTitle = im.date ? moment(im.date).format('ddd, MMM D, YYYY @ h:mm:ssa') : '';
    const title = dateTitle ? `${visitTypeTitle}: ${dateTitle}` : visitTypeTitle;
    return {
      url: im.originalUrl,
      title,
    };
  });

  const thumbnailImages = imageData.map((im) => {
    const mom = im.date ? moment(im.date) : null;
    const dateString = mom ? mom.format('ddd, MMM D, YYYY @ h:mm:ssa') : '';
    const dayString = mom ? mom.format('ddd, MMM D') : '';
    const timeString = mom ? mom.format('h:mm:ssa') : '';
    return {
      filename: im.filename,
      url: im.thumbnailUrl,
      dateString,
      dayString,
      timeString,
    }
  });

  return (
    <>
      <h5>{ visitTypeTitle }</h5>
      {thumbnailImages.map((im, index) => {
        return (
          <Col key={ im.filename } xs="2" className="px-0">
            <Card className="m-1">
              <Card.Img
                title={ im.dateString }
                alt={ im.dateString }
                src={ im.url }
                style={{maxWidth: 120, maxHeight: 192, cursor: 'pointer'}}
                onClick={() => openLightbox(lightboxImages, index)}
              />
              {/* <Card.Body>
                <Card.Text>
                  <small>{ im.dayString }</small>
                  <br/>
                  <small>{ im.timeString }</small>
                </Card.Text>
              </Card.Body> */}
            </Card>
          </Col>
        );
      })}
    </>
  );
};


function AllPhotos({
  patchFlowId,
  openLightbox,
}: {
  patchFlowId: string,
  openLightbox: (images: LightboxImage[], index: number) => void,
}) {
  const {
    data: visits,
    isLoading,
  } = useQuery(getPatchFlowVisitsByPatchFlowIdQueryKey(patchFlowId), async () => {
    return await get<PatchFlowVisit[]>(`/patch-flow-visits`, {patch_flow_id: patchFlowId});
  });

  if (!visits || isLoading) {
    // or `return null`?
    return <Loading />;
  }

  // TODO: Allow for input / viewing of visit notes.
  return (
    <>
      <h3>Photos</h3>
      <Row>
        {visits.map((v) => {
          return <VisitPhotos key={v.id} patchFlowVisit={v} openLightbox={openLightbox} />;
        })}
      </Row>
    </>
  );
};


interface ResultsTableData {
  readingOne: {
    patchReading: PatchReading,
    patchAllergenReadings: PatchAllergenReading[],
  },
  readingTwo: {
    patchReading: PatchReading,
    patchAllergenReadings: PatchAllergenReading[],
  },
  result: {
    patchResult: PatchResult,
    patchAllergenResults: PatchAllergenResult[],
  },
};

const getMaybeSuggestedPatchAllergenResultScore = (
  readingOne: PatchAllergenReadingScore | null,
  readingTwo: PatchAllergenReadingScore | null,
): PatchAllergenResultScore | null => {
  if (readingOne === null || readingTwo === null) {
    return null;
  }
  return getSuggestedPatchAllergenResultScore(readingOne, readingTwo);
}

function ResultsTable({
  visitType,
  data,
  updateReadingScore,
  updateReadingNotes,
  updateResultScore,
  updateResultNotes,
  // TODO: Provide `isSubmittingReadingOne` / `isSubmittingReadingTwo` / `isSubmittingResult` boolean
  // inputs so we can put "Submit" buttons into loading state while submitting.
  submitReading,
  submitResult,
}: {
  visitType: PatchFlowVisitType,
  data: ResultsTableData,
  updateReadingScore: (patchAllergenReadingId: string, score: PatchAllergenReadingScore | null) => void,
  updateReadingNotes: (patchAllergenReadingId: string, notes: string | null) => void,
  updateResultScore: (patchAllergenResultId: string, score: PatchAllergenResultScore | null) => void,
  updateResultNotes: (patchAllergenResultId: string, notes: string | null) => void,
  submitReading: (patchReadingId: string) => void,
  submitResult: (patchResultId: string) => void,
}) {
  const rows = PATCH_ALLERGENS.map((allergen) => {
    const wellNumber = getPatchAllergenWellNumber(allergen);
    const allergenName = getPatchAllergenName(allergen);
    const readingOneReading = data.readingOne.patchAllergenReadings.find((r) => r.allergen === allergen)!;
    const readingTwoReading = data.readingTwo.patchAllergenReadings.find((r) => r.allergen === allergen)!;
    const finalResult = data.result.patchAllergenResults.find((r) => r.allergen === allergen)!;
    const suggestedResultScore = getMaybeSuggestedPatchAllergenResultScore(readingOneReading.score, readingTwoReading.score);
    const rowBgClassName = (() => {
      switch (finalResult.score) {
        case 'negative':
          return 'bg-success bg-opacity-25';
        case 'irritant':
          return 'bg-warning bg-opacity-25';
        case 'positive':
          return 'bg-danger bg-opacity-25';
        default:
          return '';
      }
    })();
    return {
      wellNumber,
      allergenName,
      readingOneReading,
      readingTwoReading,
      finalResult,
      suggestedResultScore,
      rowBgClassName,
    };
  });

  const readingOneSubmittedAt = data.readingOne.patchReading.submitted_at ? (
    moment(data.readingOne.patchReading.submitted_at).format('ddd, MMM D, YYYY @ h:mm:ssa')
  ) : '';
  const readingTwoSubmittedAt = data.readingTwo.patchReading.submitted_at ? (
    moment(data.readingTwo.patchReading.submitted_at).format('ddd, MMM D, YYYY @ h:mm:ssa')
  ) : '';
  const resultSubmittedAt = data.result.patchResult.submitted_at ? (
    moment(data.result.patchResult.submitted_at).format('ddd, MMM D, YYYY @ h:mm:ssa')
  ) : '';

  const isReadingOneDisabled = (visitType !== 'reading-one' || data.readingOne.patchReading.submitted_at !== null);
  const isReadingTwoDisabled = (visitType !== 'reading-two' || data.readingTwo.patchReading.submitted_at !== null);
  const isOverallDisabled = (visitType !== 'reading-two' || data.result.patchResult.submitted_at !== null);

  const canSubmitReadingOne = (
    // Q: Could _feasibly_ submit during Reading #2 if really necessary?
    visitType === 'reading-one' &&
    !data.readingOne.patchReading.submitted_at &&
    data.readingOne.patchAllergenReadings.every((rdg) => rdg.score !== null)
  );
  const canSubmitReadingTwo = (
    visitType === 'reading-two' &&
    !data.readingTwo.patchReading.submitted_at &&
    data.readingTwo.patchAllergenReadings.every((rdg) => rdg.score !== null)
  );
  const canSubmitResult = (
    visitType === 'reading-two' &&
    data.readingTwo.patchReading.submitted_at !== null &&
    !data.result.patchResult.submitted_at &&
    data.result.patchAllergenResults.every((res) => res.score !== null)
  );

  const onSubmitReadingOne = () => {
    submitReading(data.readingOne.patchReading.id);
  };
  const onSubmitReadingTwo = () => {
    submitReading(data.readingTwo.patchReading.id);
  };
  const onSubmitResult = () => {
    submitResult(data.result.patchResult.id);
  };

  const readingOneSubmitCell = readingOneSubmittedAt ? (
    <p>
      <strong>Submitted:</strong>
      <br/>
      { readingOneSubmittedAt }
    </p>
  ) : (
    <Button className="w-100" disabled={ !canSubmitReadingOne } onClick={() => onSubmitReadingOne()}>
      Submit
    </Button>
  );
  const readingTwoSubmitCell = readingTwoSubmittedAt ? (
    <p>
      <strong>Submitted:</strong>
      <br/>
      { readingTwoSubmittedAt }
    </p>
  ) : (
    <Button className="w-100" disabled={ !canSubmitReadingTwo } onClick={() => onSubmitReadingTwo()}>
      Submit
    </Button>
  );
  const resultSubmitCell = resultSubmittedAt ? (
    <p>
      <strong>Submitted:</strong>
      <br/>
      { resultSubmittedAt }
    </p>
  ) : (
    <Button className="w-100" disabled={ !canSubmitResult } onClick={() => onSubmitResult()}>
      Submit
    </Button>
  );

  return (
    <Table hover responsive bordered>
      <thead>
        <tr>
          <th></th>
          <th>Allergen</th>
          <th>Reading #1</th>
          <th>Reading #2</th>
          <th>Overall</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => {
          return (
            <tr key={row.wellNumber.toString()} className={ row.rowBgClassName }>
              <td width="5%">{ row.wellNumber }</td>
              <td width="20%">{ row.allergenName }</td>
              <td width="25%">
                {/* Reading #1 */}
                <PatchAllergenReadingInput
                  isDisabled={ isReadingOneDisabled }
                  patchAllergenReading={ row.readingOneReading }
                  updateScore={ updateReadingScore }
                  updateNotes={ updateReadingNotes }
                />
              </td>
              <td width="25%">
                {/* Reading #2 */}
                <PatchAllergenReadingInput
                  isDisabled={ isReadingTwoDisabled }
                  patchAllergenReading={ row.readingTwoReading }
                  updateScore={ updateReadingScore }
                  updateNotes={ updateReadingNotes }
                />
              </td>
              <td width="25%">
                {/* Overall */}
                <PatchAllergenResultInput
                  isDisabled={ isOverallDisabled }
                  patchAllergenResult={ row.finalResult }
                  updateScore={ updateResultScore }
                  updateNotes={ updateResultNotes }
                  suggestedScore={ row.suggestedResultScore }
                />
              </td>
            </tr>
          );
        })}
        <tr>
          <td></td>
          <td></td>
          <td>{ readingOneSubmitCell }</td>
          <td>{ readingTwoSubmitCell }</td>
          <td>{ resultSubmitCell }</td>
        </tr>
      </tbody>
    </Table>
  );
};


interface ScoreOption {
  value: string,
  label: string,
}
function ScoreAndNotesInput({
  isDisabled,
  score,
  suggestedScore,
  scoreOptions,
  notes,
  isNotePublic,
  // TODO: Provider `isSavingScore` and `isSavingNote` booleans so we can put the UI into
  // a loading state while saving.
  saveScore,
  saveNotes,
}: {
  isDisabled: boolean,
  score: string | null,
  suggestedScore?: string | null,
  scoreOptions: ScoreOption[],
  notes: string | null,
  isNotePublic: boolean,
  saveScore: (score: string | null) => void,
  saveNotes: (notes: string | null) => void,
}) {
  const notesValue = notes || '';
  const scoreValue = score || '';
  const suggestedScoreLabel = suggestedScore ? (
    scoreOptions.find(({value}) => value === suggestedScore)?.label || null
  ) : null;

  const [isEditingNote, setIsEditingNote] = useState(false);
  const [currentNotesValue, setCurrentNotesValue] = useState(notesValue);

  useEffect(() => {
    setCurrentNotesValue((curr) => {
      if (curr === notesValue) {
        return curr;
      }
      return notesValue;
    });
  }, [notesValue]);

  const onChangeScore = (value: string) => {
    const score = value === '' ? null : value;
    saveScore(score);
  };

  const saveNote = () => {
    if (currentNotesValue !== notesValue) {
      const notes = currentNotesValue === '' ? null : currentNotesValue;
      saveNotes(notes);
    }
    setIsEditingNote(false);
  };

  const deleteNote = () => {
    if (notesValue !== null) {
      saveNotes(null);
    }
    setIsEditingNote(false);
  };

  const resetNote = () => {
    setCurrentNotesValue(notesValue);
    setIsEditingNote(false);
  };

  const publicNotePopover = (
    <Popover>
      <Popover.Body>
        This note will be viewable by the patient.
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Row className="g-1">
        <Col>
          <Form.Select className="w-100" disabled={ isDisabled } value={ scoreValue } onChange={(e) => onChangeScore(e.target.value)}>
            <option value=""></option>
            {scoreOptions.map(({value, label}) => {
              return <option key={ value } value={ value }>{ label }</option>
            })}
          </Form.Select>
          { suggestedScore && !score && (
            <Form.Text>
              <em>
                <strong>Suggested: </strong>
                { suggestedScoreLabel }
              </em>
              <FaPlusCircle
                title="Take Suggestion"
                color="green"
                className="ms-2 mt-0"
                style={{cursor: 'pointer'}}
                onClick={() => onChangeScore(suggestedScore)}
              />
            </Form.Text>
          )}
        </Col>
      </Row>
      {notesValue ? (
        <Card className="mt-1">
          <Card.Body className="p-2">
            <Card.Text className="fst-italic text-muted">{ notesValue }</Card.Text>
            {/* <p className="fst-italic m-0 text-muted">{ notesValue }</p> */}
          </Card.Body>
          <Card.Footer className="p-2">
            <Button className="card-link p-0" title="Edit Note" variant="link" size="sm" disabled={ isDisabled } onClick={() => setIsEditingNote(true)}>
              Edit
            </Button>
            <Button className="card-link p-0" title="Delete Note" variant="link" size="sm" disabled={ isDisabled } onClick={() => deleteNote()}>
              Delete
            </Button>
          </Card.Footer>
        </Card>
      ) : (
        <div>
          <Button title="Add Note" variant="link" size="sm" className="px-0" disabled={ isDisabled } onClick={() => setIsEditingNote(true)}>
            Add Note
          </Button>
          {isNotePublic && (
            <OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={publicNotePopover}>
              {/* <PublicNoteExclamationCircle /> */}
              <Button variant="link" size="sm">
                <FaExclamationCircle className="text-black-50"/>
              </Button>
            </OverlayTrigger>
          )}
        </div>
      )}
      <Modal show={ isEditingNote } onHide={() => setIsEditingNote(false)}>
        <Modal.Body className="p-0">
          <Form.Control
            type="text"
            as="textarea"
            rows={5}
            placeholder="Notes"
            value={ currentNotesValue }
            onChange={ (e) => setCurrentNotesValue(e.target.value) }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => resetNote()}>Cancel</Button>
          <Button variant="primary" onClick={() => saveNote()}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


function PatchAllergenReadingInput({
  isDisabled,
  patchAllergenReading,
  updateScore,
  updateNotes,
}: {
  isDisabled: boolean,
  patchAllergenReading: PatchAllergenReading,
  updateScore: (patchAllergenReadingId: string, score: PatchAllergenReadingScore | null) => void,
  updateNotes: (patchAllergenReadingId: string, notes: string | null) => void,
}) {
  const scoreOptions = PATCH_ALLERGEN_READING_SCORES.map((score) => {
    return {
      value: score,
      // Use quantitative values (e.g. ?+) rather than qualitative ("Doubtful")
      label: score,
    };
  });

  const saveScore = (scoreValue: string | null) => {
    const score = scoreValue ? (scoreValue as PatchAllergenReadingScore) : null;
    updateScore(patchAllergenReading.id, score);
  };

  const saveNotes = (notes: string | null) => {
    updateNotes(patchAllergenReading.id, notes);
  };

  return (
    <ScoreAndNotesInput
      isDisabled={ isDisabled }
      score={ patchAllergenReading.score }
      scoreOptions={ scoreOptions }
      notes={ patchAllergenReading.notes }
      isNotePublic={ false }
      saveScore={ saveScore }
      saveNotes={ saveNotes }
    />
  );
};


function PatchAllergenResultInput({
  isDisabled,
  patchAllergenResult,
  suggestedScore,
  updateScore,
  updateNotes,
}: {
  isDisabled: boolean,
  patchAllergenResult: PatchAllergenResult,
  suggestedScore: PatchAllergenResultScore | null,
  updateScore: (patchAllergenResultId: string, score: PatchAllergenResultScore | null) => void,
  updateNotes: (patchAllergenResultId: string, notes: string | null) => void,
}) {
  const scoreOptions = PATCH_ALLERGEN_RESULT_SCORES.map((score) => {
    return {
      value: score,
      label: getPatchAllergenResultScoreLabel(score),
    };
  });

  const saveScore = (scoreValue: string | null) => {
    const score = scoreValue ? (scoreValue as PatchAllergenResultScore) : null;
    updateScore(patchAllergenResult.id, score);
  };

  const saveNotes = (notes: string | null) => {
    updateNotes(patchAllergenResult.id, notes);
  };


  return (
    <ScoreAndNotesInput
      isDisabled={ isDisabled }
      score={ patchAllergenResult.score }
      suggestedScore={ suggestedScore }
      scoreOptions={ scoreOptions }
      notes={ patchAllergenResult.notes }
      isNotePublic={ true }
      saveScore={ saveScore }
      saveNotes={ saveNotes }
    />
  );
};


type PatchAllergenReadingsQueryKey = ['patch-allergen-readings', string];
const fetchPatchAllergenReadings = async ({
  queryKey,
}: {
  // This is actually ['patch-allergen-readings', string], but react-query complains if we type it that way.
  queryKey: string[],
}) => {
  const qk = queryKey as PatchAllergenReadingsQueryKey;
  return await get<PatchAllergenReading[]>('/patch-allergen-readings', {patch_reading_id: qk[1]});
};

interface UseGetResultsTableDataRetval {
  isLoading: boolean,
  data?: ResultsTableData,
};
const useGetResultsTableData = (patchFlowId: string | undefined): UseGetResultsTableDataRetval => {
  const {
    data: patchReadings,
  } = useQuery(['patch-readings', patchFlowId], async () => {
    if (!patchFlowId) {
      throw new Error('Missing patch flow ID');
    }
    return await get<PatchReading[]>(`/patch-readings`, {patch_flow_id: patchFlowId});
  }, {
    enabled: Boolean(patchFlowId),
  });

  const {
    data: patchResults,
  } = useQuery(['patch-results', patchFlowId], async () => {
    if (!patchFlowId) {
      throw new Error('Missing patch flow ID');
    }
    return await get<PatchResult[]>(`/patch-results`, {patch_flow_id: patchFlowId});
  }, {
    enabled: Boolean(patchFlowId),
  });

  const patchReadingIds = patchReadings?.map((rdg) => rdg.id);
  const patchResult = patchResults ? patchResults[0] : undefined;
  const patchResultId = patchResult?.id;

  const patchAllergenReadingsQueries = (patchReadingIds || []).map((readingId) => {
    return {
      queryKey: ['patch-allergen-readings', readingId],
      queryFn: fetchPatchAllergenReadings,
    };
  });
  const patchAllergenReadingsUseQueryResults = useQueries(patchAllergenReadingsQueries);
  const {
    data: patchAllergenResults,
  } = useQuery(['patch-allergen-results', patchResultId], async () => {
    if (!patchResultId) {
      throw new Error('Missing patch result ID');
    }
    return await get<PatchAllergenResult[]>('/patch-allergen-results', {patch_result_id: patchResultId});
  }, {
    enabled: Boolean(patchResultId),
  });

  const isAnyPatchAllergenReadingNotLoaded = patchAllergenReadingsUseQueryResults.some(({data}) => !data);
  if (!patchReadings || !patchResult || !patchAllergenResults || isAnyPatchAllergenReadingNotLoaded) {
    return {
      isLoading: true,
      data: undefined,
    };
  }
  const allPatchAllergenReadings = patchAllergenReadingsUseQueryResults.map(({data}) => data!).flat();

  // TODO: What to do if somehow these are `undefined`???
  const readingOneReading = patchReadings.find((rdg) => rdg.reading_number === 'reading-one')!;
  const readingTwoReading = patchReadings.find((rdg) => rdg.reading_number === 'reading-two')!;
  const readingOneAllergenReadings = allPatchAllergenReadings.filter((r) => r.patch_reading_id === readingOneReading.id);
  const readingTwoAllergenReadings = allPatchAllergenReadings.filter((r) => r.patch_reading_id === readingTwoReading.id);

  const resultsTableData = {
    readingOne: {
      patchReading: readingOneReading,
      patchAllergenReadings: readingOneAllergenReadings,
    },
    readingTwo: {
      patchReading: readingTwoReading,
      patchAllergenReadings: readingTwoAllergenReadings,
    },
    result: {
      patchResult,
      patchAllergenResults,
    },
  };
  return {
    isLoading: false,
    data: resultsTableData,
  };
};

type PatchAllergenReadingPatchUpdate = Partial<{
  score: PatchAllergenReadingScore | null,
  notes: string | null,
}>;
const updatePatchAllergenReading = async ({
  patchAllergenReadingId,
  update
}: {
  patchAllergenReadingId: string,
  update: PatchAllergenReadingPatchUpdate,
}) => {
  return await patch<PatchAllergenReading>(`/patch-allergen-readings/${patchAllergenReadingId}`, update);
};
const submitPatchReading = async (patchReadingId: string) => {
  return await post<PatchReading>(`/patch-readings/${patchReadingId}/submit`);
};

type PatchAllergenResultPatchUpdate = Partial<{
  score: PatchAllergenResultScore | null,
  notes: string | null,
}>;
const updatePatchAllergenResult = async ({
  patchAllergenResultId,
  update
}: {
  patchAllergenResultId: string,
  update: PatchAllergenResultPatchUpdate,
}) => {
  return await patch<PatchAllergenResult>(`/patch-allergen-results/${patchAllergenResultId}`, update);
};
const submitPatchResult = async (patchResultId: string) => {
  return await post<PatchResult>(`/patch-results/${patchResultId}/submit`);
};

interface HasId {
  id: string,
};

function upsertWithId<T extends HasId>(maybeArray: T[] | undefined, elem: T): T[] {
  const array = maybeArray || [];
  const index = array.findIndex((x) => x.id === elem.id);
  if (index === -1) {
    return [...array, elem];
  }
  const before = array.slice(0, index);
  const after = array.slice(index + 1);
  return [
    ...before,
    elem,
    ...after,
  ];
};

const getPatientName = (patient: PatientBasic) => {
  return `${patient.first_name.substring(0, 1)}. ${patient.last_name}`;
};

const getPatientDobStr = (patient: PatientBasic) => {
  const elem = patient.birthdate?.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (!elem) {
    return null;
  }
  return `${elem[2]}/${elem[3]}/${elem[1]}`;
}

function AppointmentRoute() {
  const queryClient = useQueryClient();
  const { patchFlowVisitId } = useParams();
  const [lightboxImages, setLightboxImages] = useState<LightboxImage[]>();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const {
    data: patchFlowVisit,
    isLoading: isLoadingPatchFlowVisit,
  } = useQuery(getPatchFlowVisitByIdQueryKey(patchFlowVisitId), async () => {
    if (!patchFlowVisitId) {
      throw new Error('Missing patch flow visit ID');
    }
    return await get<PatchFlowVisit>(`/patch-flow-visits/${patchFlowVisitId}`);
  });

  const patchFlowId = patchFlowVisit?.patch_flow_id;
  const {
    data: resultsTableData,
  } = useGetResultsTableData(patchFlowId);

  const {
    data: patchFlow,
  } = useQuery(getPatchFlowByIdQueryKey(patchFlowId), async () => {
    if (!patchFlowId) {
      throw new Error('Missing patch flow ID');
    }
    return await get<PatchFlow>(`/patch-flows/${patchFlowId}`);
  }, {
    enabled: Boolean(patchFlowId),
  });

  const patientId = patchFlow?.patient_id;
  const {
    data: patient,
  } = useQuery(getPatientBasicByIdQueryKey(patientId), async () => {
    if (!patientId) {
      throw new Error('Missing patient ID');
    }
    return await get<PatientBasic>(`/patient-basics/${patientId}`);
  }, {
    enabled: Boolean(patientId),
  });

  const updatePatchAllergenReadingMutation = useMutation(updatePatchAllergenReading, {
    onSuccess(patchAllergenReading) {
      queryClient.setQueryData<PatchAllergenReading[]>(['patch-allergen-readings', patchAllergenReading.patch_reading_id], (maybeReadings) => {
        return upsertWithId(maybeReadings, patchAllergenReading);
      });
    },
  });
  const updatePatchAllergenResultMutation = useMutation(updatePatchAllergenResult, {
    onSuccess(patchAllergenResult) {
      queryClient.setQueryData<PatchAllergenResult[]>(['patch-allergen-results', patchAllergenResult.patch_result_id], (maybeResults) => {
        return upsertWithId(maybeResults, patchAllergenResult);
      });
    },
  });
  const submitPatchReadingMutation = useMutation(submitPatchReading, {
    onSuccess(patchReading) {
      queryClient.setQueryData<PatchReading[]>(['patch-readings', patchReading.patch_flow_id], (maybePatchReadings) => {
        return upsertWithId(maybePatchReadings, patchReading);
      });
    },
  });
  const submitPatchResultMutation = useMutation(submitPatchResult, {
    onSuccess(patchResult) {
      queryClient.setQueryData<PatchResult[]>(['patch-results', patchResult.patch_flow_id], (maybePatchResults) => {
        return upsertWithId(maybePatchResults, patchResult);
      });
    },
  });

  // Maybe use `useState` to know _which_ reading/result is relevant
  // for when `mutation.isLoading` is true (to be able to pass in the
  // loading state to the `ResultsTable`)
  const updateReadingNotes = (patchAllergenReadingId: string, notes: string | null) => {
    const update = {notes};
    updatePatchAllergenReadingMutation.mutate({patchAllergenReadingId, update})
  };
  const updateReadingScore = (patchAllergenReadingId: string, score: PatchAllergenReadingScore | null) => {
    const update = {score};
    updatePatchAllergenReadingMutation.mutate({patchAllergenReadingId, update})
  };
  const updateResultNotes = (patchAllergenResultId: string, notes: string | null) => {
    const update = {notes};
    updatePatchAllergenResultMutation.mutate({patchAllergenResultId, update})
  };
  const updateResultScore = (patchAllergenResultId: string, score: PatchAllergenResultScore | null) => {
    const update = {score};
    updatePatchAllergenResultMutation.mutate({patchAllergenResultId, update})
  };


  if (isLoadingPatchFlowVisit || !patchFlowVisit || !resultsTableData || !patchFlow || !patient) {
    // OR, if `!patchFlowVisit`, then <Navigate to="/appointments" />?
    return <Loading />;
  }

  const visitTypeTitle = getVisitTypeTitle(patchFlowVisit.visit_type);
  const openLightbox = (images: LightboxImage[], index: number) => {
    setLightboxImages(images);
    setCurrentImageIndex(index);
  };
  const closeLightbox = () => {
    setLightboxImages(undefined);
    setCurrentImageIndex(0);
  };
  const patientName = getPatientName(patient);
  const patientDobStr = getPatientDobStr(patient);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <h1>Appointment: { visitTypeTitle }</h1>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="3">
          <h3>Patient Information</h3>
          <p>
            <strong>Name:</strong> { patientName }
            <br />
            <strong>DOB:</strong> { patientDobStr }
          </p>
          <p>
            <a className="btn btn-primary" target="_blank" rel="noreferrer" href={ `/appointments/${patchFlowVisitId}/video`}>
              Join Video Visit <FaExternalLinkAlt className="ms-2 mb-1" />
            </a>
          </p>
          <AllPhotos
            patchFlowId={ patchFlowVisit.patch_flow_id }
            openLightbox={ openLightbox }
          />
        </Col>
        <Col md="9">
          <ResultsTable
            visitType={ patchFlowVisit.visit_type }
            // visitType="reading-two"
            data={ resultsTableData }
            updateReadingScore={ updateReadingScore }
            updateReadingNotes={ updateReadingNotes }
            updateResultScore={ updateResultScore }
            updateResultNotes={ updateResultNotes }
            submitReading={ submitPatchReadingMutation.mutate }
            submitResult={ submitPatchResultMutation.mutate }
          />
        </Col>
      </Row>
      {lightboxImages && (
        <LightboxPhotos
          images={ lightboxImages }
          currentIndex={ currentImageIndex }
          onClose={ closeLightbox }
        />
      )}
    </>
  );
}

export default AppointmentRoute;