import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { get } from 'webapp-common/api/RestApi';
import PatchFlowVisit from 'webapp-common/models/PatchFlowVisit';

import Loading from '../components/Loading';
import VideoApp from '../components/VideoApp';

import { getPatchFlowVisitByIdQueryKey } from '../utils/query-keys';

interface TwilioVideoAccessTokenResponse {
  token: string,
}

function AppointmentVideoRoute() {
  const { patchFlowVisitId } = useParams();

  const {
    data: patchFlowVisit,
    isLoading: isLoadingPatchFlowVisit,
  } = useQuery(getPatchFlowVisitByIdQueryKey(patchFlowVisitId), async () => {
    if (!patchFlowVisitId) {
      throw new Error('Missing patch flow visit ID');
    }
    return await get<PatchFlowVisit>(`/patch-flow-visits/${patchFlowVisitId}`);
  });

  const fetchTokenMutation = useMutation(async () => {
    if (!patchFlowVisitId) {
      throw new Error('Missing patch flow visit ID');
    }
    const { token } = await get<TwilioVideoAccessTokenResponse>(`/patch-flow-visits/${patchFlowVisitId}/twilio-video-access-token`);
    return token;
  });

  if (isLoadingPatchFlowVisit || !patchFlowVisit) {
    // OR, if `!patchFlowVisit`, then <Navigate to="/appointments" />?
    return <Loading />;
  }

  // TODO: Close the window?
  const onExit = () => {
    window.close();
  };

  console.log('HERE NOW');

  return (
    <VideoApp
      roomName="Video Visit"
      onExit={ onExit }
      fetchToken={ fetchTokenMutation.mutateAsync }
      isFetchingToken={ fetchTokenMutation.isLoading }
    />
  );
}

export default AppointmentVideoRoute;
