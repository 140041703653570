import { FormEventHandler, useEffect, useRef, useState, CSSProperties } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';

import { FaCog, FaMicrophone, FaMicrophoneSlash, FaPhoneSlash, FaVideo, FaVideoSlash } from 'react-icons/fa';

import {
  LocalAudioTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteTrackPublication,
  RemoteVideoTrack,
  Participant,
} from 'twilio-video';

import { VideoProvider } from '../twilio/components/VideoProvider';
import { ErrorCallback } from '../twilio/types';
import useRoomState from '../twilio/hooks/useRoomState/useRoomState';
import useHeight from '../twilio/hooks/useHeight/useHeight';
import useVideoContext from '../twilio/hooks/useVideoContext/useVideoContext';
import useMainParticipant from '../twilio/hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../twilio/hooks/useParticipants/useParticipants';
import usePublications from '../twilio/hooks/usePublications/usePublications';
import useTrack from '../twilio/hooks/useTrack/useTrack';
import useMediaStreamTrack from '../twilio/hooks/useMediaStreamTrack/useMediaStreamTrack';
import useDevices from '../twilio/hooks/useDevices/useDevices';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_AUDIO_INPUT_KEY, SELECTED_VIDEO_INPUT_KEY } from '../twilio/constants';
import useActiveSinkId from '../twilio/hooks/useActiveSinkId/useActiveSinkId';
import useLocalAudioToggle from '../twilio/hooks/useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../twilio/hooks/useLocalVideoToggle/useLocalVideoToggle';


function JoinScreen({
  roomName,
  fetchToken,
  isFetchingToken,
  onExit,
}: {
  roomName: string,
  fetchToken: () => Promise<string>,
  isFetchingToken: boolean,
  onExit: () => void,
}) {
  const { connect, isConnecting, getAudioAndVideoTracks } = useVideoContext();

  // TODO: Figure out how to move this so that the video isn't on before joining...
  useEffect(() => {
    (async () => {
      await getAudioAndVideoTracks();
    })();
  }, [getAudioAndVideoTracks]);

  const submit: FormEventHandler = async (event) => {
    event.preventDefault();
    const token = await fetchToken();
    // room name is encoded in the token
    connect(token);
  };

  const onClickExit = () => {
    // stop/unpublish tracks?
    onExit();
  };

  // TODO: handle case where there are no audio/video devices...

  const isJoining = isFetchingToken || isConnecting;

  return (
    <Row className="justify-content-center mt-5">
      <Col sm="6" lg="4">
        <p>Joining room: <strong>{ roomName }</strong></p>
        <div className="mb-3" style={{ height: '100px'}}>
          <VideoPreview />
        </div>
        <SelectVideoInputDevice />
        <SelectAudioInputDevice />
        <SelectAudioOutputDevice />
        <ButtonGroup className="mb-3">
          <ToggleAudioButton />
          <ToggleVideoButton />
        </ButtonGroup>

        <Form className="mb-3" onSubmit={ submit }>
          <Button type="submit" className="mb-3 w-100" disabled={ isJoining }>
            {isJoining ? 'Joining...' : 'Join'}
          </Button>
          <Button variant="secondary" className="mb-3 w-100" disabled={ isJoining } onClick={ onClickExit }>
            Exit
          </Button>
        </Form>
      </Col>
    </Row>
  );
}

function VideoPreview() {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack | undefined;

  if (!videoTrack) {
    return null;
  }

  return (
    <VideoFeed track={ videoTrack } isLocal />
  );
}


const participantInfoStyle: CSSProperties = {
  position: 'absolute',
  zIndex: 2,
};

const uuidStartRegex = new RegExp(/^[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}:/i);
const getParticipantName = (participant: Participant): string => {
  const { identity } = participant;
  if (uuidStartRegex.test(identity)) {
    return identity.substring(37);
  } else {
    return identity;
  }
}

function ParticipantVideo({
  participant,
  isLocalParticipant,
  disableAudio,
}: {
  participant: Participant,
  isLocalParticipant?: boolean,
  disableAudio?: boolean,
}) {
  const publications = usePublications(participant);
  // remove all screenshare tracks (?)
  const filteredPublications = publications.filter(p => !p.trackName.includes('screen'));
  const participantName = getParticipantName(participant);

  return (
    <>
      <div style={participantInfoStyle}>
        <h5>{participantName}</h5>
      </div>
      {filteredPublications.map((p) => {
        return (
          <Publication key={ p.kind } publication={ p } isLocalParticipant={ isLocalParticipant } disableAudio={ disableAudio } />
        );
      })}
    </>
  )
};

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

function Publication({
  publication,
  isLocalParticipant,
  disableAudio,
}: {
  publication: TrackPublication,
  isLocalParticipant?: boolean,
  disableAudio?: boolean,
}) {
  const track = useTrack(publication);

  if (!track) return null;

  switch (track.kind) {
    case 'video':
      return <VideoFeed track={track} isLocal={isLocalParticipant} />;
    case 'audio':
      return disableAudio ? null : <AudioFeed track={track} />;
    default:
      return null;
  }
};


function VideoFeed({
  track,
  isLocal,
}: {
  track: LocalVideoTrack | RemoteVideoTrack,
  isLocal?: boolean,
}) {
  const ref = useRef<HTMLVideoElement>(null!);
  const mediaStreamTrack = useMediaStreamTrack(track);
  // const dimensions = useVideoTrackDimensions(track);
  // const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    track.attach(el);
    return () => {
      track.detach(el);

      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;
    };
  }, [track]);

  // The local video track is mirrored if it is not facing the environment.
  const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const isFlippable = isLocal && !track.name.includes('screen');
  const style = {
    width: '100%',
    height: '100%',
    transform: isFlippable && isFrontFacing ? 'scaleX(-1)' : '',
    // objectFit: isPortrait || track.name.includes('screen') ? ('contain' as const) : ('cover' as const),
  };

  return <video ref={ref} style={style} />;
}

function AudioFeed({
  track,
}: {
  track: LocalAudioTrack | RemoteAudioTrack,
}) {
  const { activeSinkId } = useActiveSinkId();
  const audioEl = useRef<HTMLAudioElement>(null!);

  useEffect(() => {
    audioEl.current = track.attach();
    audioEl.current.setAttribute('data-cy-audio-track-name', track.name);
    document.body.appendChild(audioEl.current);
    return () =>
      track.detach().forEach((el) => {
        el.remove();

        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        // See: https://github.com/twilio/twilio-video.js/issues/1528
        el.srcObject = null;
      });
  }, [track]);

  useEffect(() => {
    audioEl.current?.setSinkId?.(activeSinkId);
  }, [activeSinkId]);

  return null;
}


function VideoScreen() {
  const height = useHeight();

  const mainParticipant = useMainParticipant();
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const participants = useParticipants();

  // overflow: hidden because otherwise there's just a little bit of scroll... not sure why.
  return (
    <div className="position-relative" style={{height, overflow: 'hidden'}}>
      <div className="position-fixed end-0 pe-2 pt-2" style={ {zIndex: 3} }>
        <div className="mb-2">
          <ToggleAudioButton />
        </div>
        <div className="mb-2">
          <ToggleVideoButton />
        </div>
        <div className="mb-2">
          <VideoSettingsButton />
        </div>
        <div className="mb-2">
          <EndVisitButton />
        </div>
      </div>
      <div style={{height: '100%', backgroundColor: 'lightgray'}}>
        <ParticipantVideo participant={ mainParticipant } disableAudio={ true } isLocalParticipant={ mainParticipant === localParticipant } />
      </div>
      <div className="position-absolute bottom-0 start-0 d-flex flex-row" style={{height: '25%'}}>
        <div className="border border-dark ms-1 me-1" style={{ height: '100%', aspectRatio: '1 / 1', backgroundColor: 'lightgray'}}>
          <ParticipantVideo participant={ localParticipant } isLocalParticipant={ true } />
        </div>
        {participants.map((p) => {
          return (
            <div key={p.identity} className="border border-dark ms-1 me-1" style={{ height: '100%', aspectRatio: '1 / 1', backgroundColor: 'lightgray'}}>
              <ParticipantVideo participant={ p } />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function ToggleAudioButton() {
  const [isEnabled, toggleIsEnabled] = useLocalAudioToggle();
  const icon = isEnabled ? <FaMicrophone /> : <FaMicrophoneSlash />;
  return (
    <Button variant="secondary" onClick={() => toggleIsEnabled()}>
      { icon }
    </Button>
  );
}

function ToggleVideoButton() {
  const [isEnabled, toggleIsEnabled] = useLocalVideoToggle();
  const icon = isEnabled ? <FaVideo /> : <FaVideoSlash />;
  return (
    <Button variant="secondary" onClick={() => toggleIsEnabled()}>
      { icon }
    </Button>
  );
}

function VideoSettingsButton() {
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  return (
    <>
      <Offcanvas show={showOffCanvas} onHide={() => setShowOffCanvas(false)}>
        <Offcanvas.Header>
          <Offcanvas.Title>Settings</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <VideoSettings />
        </Offcanvas.Body>
      </Offcanvas>
      <Button variant="secondary" onClick={() => setShowOffCanvas(true)}>
        <FaCog />
      </Button>
    </>
  );
}

function EndVisitButton() {
  const { room } = useVideoContext();

  return (
    <Button variant="danger" onClick={() => room?.disconnect()}>
      <FaPhoneSlash />
    </Button>
  )
}

function VideoSettings() {
  return (
    <>
      <SelectVideoInputDevice />
      <SelectAudioInputDevice />
      <SelectAudioOutputDevice />
    </>
  );
}

function SelectVideoInputDevice() {
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  const replaceTrack = (newDeviceId: string) => {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  return (
    <Form.Group className="mb-3" controlId="video-input-device">
      <Form.FloatingLabel label="Video Input">
        <Form.Select value={ localVideoInputDeviceId || '' } onChange={(e) => replaceTrack(e.target.value)}>
          {videoInputDevices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>{ device.label }</option>
          ))}
        </Form.Select>
      </Form.FloatingLabel>
    </Form.Group>
  );
}

function SelectAudioInputDevice() {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <Form.Group className="mb-3" controlId="audio-input-device">
      <Form.FloatingLabel label="Audio Input">
        <Form.Select value={ localAudioInputDeviceId || '' } onChange={(e) => replaceTrack(e.target.value)}>
          {audioInputDevices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>{ device.label }</option>
          ))}
        </Form.Select>
      </Form.FloatingLabel>
    </Form.Group>
  );
}

function SelectAudioOutputDevice() {
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useActiveSinkId();

  return (
    <Form.Group className="mb-3" controlId="audio-output-device">
      <Form.FloatingLabel label="Audio Output">
        <Form.Select value={ activeSinkId || '' } onChange={(e) => setActiveSinkId(e.target.value)}>
          {audioOutputDevices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>{ device.label }</option>
          ))}
        </Form.Select>
      </Form.FloatingLabel>
    </Form.Group>
  );
}

function VideoApp({
  roomName,
  fetchToken,
  isFetchingToken,
  onExit,
}: {
  roomName: string,
  fetchToken: () => Promise<string>,
  isFetchingToken: boolean,
  onExit: () => void,
}) {
  // TODO: Anything we want to set here in the options?
  const connectionOptions = {};
  // TODO: Anything we want to do with errors?
  const onError: ErrorCallback = (err) => {
    console.log('ERROR', err);
  };

  return (
    <VideoProvider options={connectionOptions} onError={onError}>
      <VideoAppInner
        roomName={ roomName }
        onExit={ onExit }
        fetchToken={ fetchToken }
        isFetchingToken={ isFetchingToken }
      />
    </VideoProvider>
  );
};


function VideoAppInner({
  roomName,
  fetchToken,
  isFetchingToken,
  onExit,
}: {
  roomName: string,
  fetchToken: () => Promise<string>,
  isFetchingToken: boolean,
  onExit: () => void,
}) {
  const { localTracks } = useVideoContext();
  const tracksRef = useRef(localTracks);

  useEffect(() => {
    tracksRef.current = localTracks;
  }, [localTracks]);

  useEffect(() => {
    return () => {
      tracksRef.current.forEach((track) => {
        track.stop();
      });
    };
  }, []);

  const roomState = useRoomState();

  if (roomState === 'disconnected') {
    return (
      <JoinScreen
        roomName={ roomName }
        fetchToken={ fetchToken }
        isFetchingToken={ isFetchingToken }
        onExit={ onExit }
      />
    );
  } else {
    return <VideoScreen />;
  }
};


export default VideoApp;