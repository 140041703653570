import { ReactNode, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import {
  createSearchParams,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useQueryClient } from 'react-query';

import './App.scss';

import HomeRoute from './routes/HomeRoute';
import QuestionnairesRoute from './routes/QuestionnairesRoute';
import QuestionnaireRoute from './routes/QuestionnaireRoute';
import AppointmentsRoute from './routes/AppointmentsRoute';
import AppointmentRoute from './routes/AppointmentRoute';
import AppointmentVideoRoute from './routes/AppointmentVideoRoute';
import JoinVideoRoute from './routes/JoinVideoRoute';
import VideoRoute from './routes/VideoRoute';
import PatientsRoute from './routes/PatientsRoute';
import LoginRoute from './routes/LoginRoute';

import { useAuth } from 'webapp-common/auth/AuthProvider';
import Loading from './components/Loading';
import Navbar from './components/Navbar';


function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { pathname } = location;

  const auth = useAuth();
  const [hasInitiatedAuthUserFetch, setHasInitiatedAuthUserFetch] = useState<boolean>(false);
  const [isLoadingAuthUser, setIsLoadingAuthUser] = useState<boolean>(true);

  const loadAuthUser = async () => {
    if (hasInitiatedAuthUserFetch) return;
    setHasInitiatedAuthUserFetch(true);
    await auth.load();
    setIsLoadingAuthUser(false);
  };

  useEffect(() => {
    loadAuthUser();
  });

  if (isLoadingAuthUser) {
    return <Loading />;
  }

  const isAuthenticated = auth.user !== null;
  
  if (!isAuthenticated && pathname !== '/login') {
    return <Navigate to="/login" state={ {from: location} } replace />;
  }

  const searchPatients = (query: string) => {
    const searchParams = createSearchParams({q: query});
    navigate({
      pathname: "/patients",
      search: `?${searchParams.toString()}`,
    });
  };


  const signOut = async () => {
    await auth.signout();
    queryClient.clear();
    navigate('/login');
  };

  const appointmentVideoRe = /^\/appointments\/[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}\/video$/;
  const isFullScreen = pathname === '/video' || appointmentVideoRe.test(pathname);

  return (
    <RouteContainer
      isFullScreen={ isFullScreen }
      navbar={ <Navbar isAuthenticated={isAuthenticated} searchPatients={searchPatients} signOut={signOut} /> }
    >
      <Routes>
        <Route path="/login" element={<LoginRoute />} />
        <Route path="/" element={<HomeRoute />} />
        <Route path="questionnaires" element={<QuestionnairesRoute />} />
        <Route path="questionnaires/:questionnaireId" element={<QuestionnaireRoute />} />
        <Route path="appointments" element={<AppointmentsRoute />} />
        <Route path="appointments/:patchFlowVisitId" element={<AppointmentRoute />} />
        <Route path="appointments/:patchFlowVisitId/video" element={<AppointmentVideoRoute />} />
        <Route path="patients" element={<PatientsRoute />} />
        <Route path="join-video" element={<JoinVideoRoute />} />
        <Route path="video" element={<VideoRoute />} />
      </Routes>
    </RouteContainer>
  );
}

function RouteContainer({
  isFullScreen,
  navbar,
  children,
}: {
  isFullScreen: boolean,
  navbar: ReactNode,
  children: ReactNode,
}) {
  if (isFullScreen) {
    return <Container fluid className="p-0">{ children }</Container>;
  } else {
    return (
      <>
        {navbar}
        <Container fluid className="pt-5 pb-5">
          { children }
        </Container>
      </>
    );
  }
}

export default App;
