import Table from 'react-bootstrap/Table';

import QuestionnaireMedication from 'webapp-common/models/QuestionnaireMedication';


export default function QuestionnaireMedicationsTable({
  medications,
}: {
  medications: QuestionnaireMedication[],
}) {
  if (medications.length === 0) {
    return <p><em>None</em></p>;
  }
  return (
    <Table hover bordered size="sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Dose</th>
          <th>Frequency</th>
          <th>Reason</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        {medications.map((medication) => {
          return (
            <tr key={ medication.id }>
              <td>{ medication.name }</td>
              <td>{ medication.dose }</td>
              <td>{ medication.frequency }</td>
              <td>{ medication.reason }</td>
              <td>{ medication.notes }</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}