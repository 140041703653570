import PatchAllergenReadingScore from 'webapp-common/models/PatchAllergenReadingScore';
import PatchAllergenResultScore from 'webapp-common/models/PatchAllergenResultScore';

const isPositiveAllergenReadingScore = (score: PatchAllergenReadingScore): boolean => {
  return (score === '1+') || (score === '2++') || (score === '3+++');
};

const getSuggestedPatchAllergenResultScore = (
  readingOne: PatchAllergenReadingScore,
  readingTwo: PatchAllergenReadingScore,
): PatchAllergenResultScore => {
  /**
   * Positive reaction:
   * (1+), (2++), OR (3+++) reaction at reading #1 OR reading #2 [most ACD will
   * increase in intensity at reading #2, but there are some early reactors who may
   * see the intensity of reaction peak at reading #1 and decrease by reading #2 -
   * i.e. Balsam of Peru, lanolin, Fragrance mix, and Cocamidopropyl betaine
   * 
   * (-) or (?+) OR (IR) at reading #1, followed by escalation to (1+), (2++), or (3+++) reaction at reading #2
   */
  if (isPositiveAllergenReadingScore(readingOne) || isPositiveAllergenReadingScore(readingTwo)) {
    return 'positive';
  }

  /**
   * Negative testing:
   * (-) reaction at reading #1 and reading #2
   * (?+) Doubtful reaction at reading #1 with resolution of reaction by reading #2
   */
  if (((readingOne === '-') || (readingOne === '?+')) && readingTwo === '-') {
    return 'negative';
  }

  /**
   * Irritant reaction:
   * (IR) at reading #1 without escalation of reaction at reading #2
   * (IR) at reading #2
   */
  if ((readingOne === 'IR') || (readingTwo === 'IR')) {
    return 'irritant';
  }

  /**
   * Doubtful reaction:
   * (?+) Doubtful reaction at reading #1 or reading #2
   * consider irritant reaction diagnosis if present at both readings with
   * similar intensity, or if clearly worse at reading #2, might be a low-level
   * allergic contact dermatitis.
   * 
   * NOTE: Actually, these should instead be 'irritant' since we are not going
   * to have "doubtful" results.
   */
  if ((readingOne === '?+') || (readingTwo === '?+')) {
    return 'irritant';
  }

  // We already handled all cases (see tests).
  throw new Error('Unreachable');
};

export {
  getSuggestedPatchAllergenResultScore,
}