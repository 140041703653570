
const getQuestionnaireByIdQueryKey = (questionnaireId: string | undefined) => {
  return ['questionnaire', questionnaireId];
};

const getPendingQuestionnairesByProviderIdQueryKey = (providerId: string | undefined) => {
  return ['questionnaires', providerId, 'pending'];
};

const getReviewingQuestionnairesByProviderIdQueryKey = (providerId: string | undefined) => {
  return ['questionnaires', providerId, 'reviewing'];
};

const getReviewedQuestionnairesByProviderIdQueryKey = (providerId: string | undefined) => {
  return ['questionnaires', providerId, 'reviewed'];
};

const getQuestionnaireMedicationsByQuestionnaireIdQueryKey = (questionnaireId: string | undefined) => {
  return ['questionnaire-medications', questionnaireId];
};

const getProviderScheduleSlotsByProviderIdQueryKey = (providerId: string | undefined) => {
  return ['provider-schedule-slots', providerId];
};

const getPatientBasicByIdQueryKey = (patientId: string | undefined) => {
  return ['patient-basic', patientId];
};

const getPatientBasicsByIdsQueryKey = (patientIds: string | undefined) => {
  return ['patient-basics', patientIds];
};

const getPatchFlowByIdQueryKey = (patchFlowId: string | undefined) => {
  return ['patch-flow', patchFlowId];
};

const getPatchFlowsByIdsQueryKey = (patchFlowIds: string | undefined) => {
  return ['patch-flows', patchFlowIds];
};

const getPatchFlowVisitByIdQueryKey = (patchFlowVisitId: string | undefined) => {
  return ['patch-flow-visit', patchFlowVisitId];
};

const getPatchFlowVisitsByProviderIdQueryKey = (providerId: string | undefined, dateRange: {start: Date, end: Date}) => {
  return ['patch-flow-visits', providerId, dateRange];
};

const getPatchFlowVisitsByPatchFlowIdQueryKey = (patchFlowId: string | undefined) => {
  return ['patch-flow-visits', patchFlowId];
};

const getPatchFlowVisitPhotosByPatchFlowVisitIdQueryKey = (patchFlowVisitId: string | undefined) => {
  return ['patch-flow-visit', patchFlowVisitId, 'photos'];
}

export {
  getQuestionnaireByIdQueryKey,
  getPendingQuestionnairesByProviderIdQueryKey,
  getReviewingQuestionnairesByProviderIdQueryKey,
  getReviewedQuestionnairesByProviderIdQueryKey,
  getQuestionnaireMedicationsByQuestionnaireIdQueryKey,
  getProviderScheduleSlotsByProviderIdQueryKey,
  getPatientBasicByIdQueryKey,
  getPatientBasicsByIdsQueryKey,
  getPatchFlowByIdQueryKey,
  getPatchFlowsByIdsQueryKey,
  getPatchFlowVisitByIdQueryKey,
  getPatchFlowVisitsByProviderIdQueryKey,
  getPatchFlowVisitsByPatchFlowIdQueryKey,
  getPatchFlowVisitPhotosByPatchFlowVisitIdQueryKey,
};