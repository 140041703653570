import { FormEventHandler, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import RbNavbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { NavLink } from 'react-router-dom';

function Navbar({
  isAuthenticated,
  searchPatients,
  signOut,
}: {
  isAuthenticated: boolean,
  searchPatients: (query: string) => void,
  signOut: () => void,
}) {
  const [patientSearchQuery, setPatientSearchQuery] = useState('');

  const submitPatientSearch: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    searchPatients(patientSearchQuery);
  };

  const getSigninOrSignout = () => {
    if (isAuthenticated) {
      return (
        <RbNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink to="/questionnaires" className="nav-link">Questionnaires</NavLink>
            <NavLink to="/appointments" className="nav-link">Appointments</NavLink>
            <NavLink to="/join-video" className="nav-link text-danger">Join Video</NavLink>
          </Nav>
          <Form className="d-flex me-2 mb-2 mb-lg-0" onSubmit={submitPatientSearch}>
            <Form.Control className="me-2" name="q" type="search" placeholder="Patient Search" value={patientSearchQuery} onChange={(e) => setPatientSearchQuery(e.target.value)} />
            {/* <input className="form-control me-2" type="search" placeholder="Patient Search" aria-label="Patient Search"></input> */}
            <Button variant="outline-primary" type="submit">Search</Button>
          </Form>
          <Button onClick={signOut} variant="outline-danger">Sign Out</Button>
        </RbNavbar.Collapse>
      );
    } else {
      return (
        <RbNavbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Item>
              <NavLink to="/login" className="nav-link">Sign In</NavLink>
            </Nav.Item>
          </Nav>
        </RbNavbar.Collapse>
      );
    }
  }

  const signinout = getSigninOrSignout();

  return (
    <RbNavbar bg="light" expand="lg">
      <Container fluid>
        <LinkContainer to="/">
          <RbNavbar.Brand>Evme</RbNavbar.Brand>
        </LinkContainer>
        <RbNavbar.Toggle aria-controls="basic-navbar-nav" />
        { signinout }
      </Container>
    </RbNavbar>
  );
}

export default Navbar;