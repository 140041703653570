import moment from 'moment';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { get, RequestQueryParams } from 'webapp-common/api/RestApi';
import PatchFlow from 'webapp-common/models/PatchFlow';
import PatientBasic from 'webapp-common/models/PatientBasic';
import QuestionnaireReviewStatus from 'webapp-common/models/QuestionnaireReviewStatus';
import Questionnaire from 'webapp-common/models/Questionnaire';
import { useAuth } from 'webapp-common/auth/AuthProvider';

import Loading from '../components/Loading';
import {
  getPatchFlowsByIdsQueryKey,
  getPatientBasicsByIdsQueryKey,
  getPendingQuestionnairesByProviderIdQueryKey,
  getQuestionnaireByIdQueryKey,
  getReviewedQuestionnairesByProviderIdQueryKey,
  getReviewingQuestionnairesByProviderIdQueryKey,
} from '../utils/query-keys';


const createQueryParams = (providerId: string, queryParams: RequestQueryParams): RequestQueryParams => {
  return {
    ...queryParams,
    provider_id: providerId,
  };
};

const fetchPendingQuestionnaires = async (providerId: string) => {
  const pendingQueryParams = createQueryParams(providerId, {
    review_status: 'pending',
    sort_by: 'submitted_at',
    sort_dir: 'asc',
  });
  return await get<Questionnaire[]>('/questionnaires', pendingQueryParams);
};

const fetchReviewingQuestionnaires = async (providerId: string) => {
  const reviewingQueryParams = createQueryParams(providerId, {
    review_status: 'reviewing',
    sort_by: 'submitted_at',
    sort_dir: 'asc',
  });
  return await get<Questionnaire[]>('/questionnaires', reviewingQueryParams);
};

const fetchReviewedQuestionnaires = async (providerId: string) => {
  const reviewedQueryParams = createQueryParams(providerId, {
    review_status: 'reviewed',
    sort_by: 'reviewed_at',
    sort_dir: 'desc',
  });
  return await get<Questionnaire[]>('/questionnaires', reviewedQueryParams);
};


function QuestionnairesRoute() {
  const auth = useAuth();
  const providerId = auth.user?.id as string;
  const queryClient = useQueryClient();

  const setQuestionnaires = (questionnaires: Questionnaire[]) => {
    questionnaires.forEach((q) => {
      queryClient.setQueryData(getQuestionnaireByIdQueryKey(q.id), q);
    });
  };
  const {
    data: pendingQuestionnaires,
    isLoading: isLoadingPendingQuestionnaires,
  } = useQuery(getPendingQuestionnairesByProviderIdQueryKey(providerId), async () => {
    return fetchPendingQuestionnaires(providerId);
  }, {
    onSuccess: setQuestionnaires,
  });
  const {
    data: reviewingQuestionnaires,
    isLoading: isLoadingReviewingQuestionnaires,
   } = useQuery(getReviewingQuestionnairesByProviderIdQueryKey(providerId), async () => {
    return fetchReviewingQuestionnaires(providerId);
  }, {
    onSuccess: setQuestionnaires,
  });
  const {
    data: reviewedQuestionnaires,
    isLoading: isLoadingReviewedQuestionnaires,
  } = useQuery(getReviewedQuestionnairesByProviderIdQueryKey(providerId), async () => {
    return fetchReviewedQuestionnaires(providerId);
  }, {
    onSuccess: setQuestionnaires,
  });
  const hasAllQuestionnaires = Boolean(pendingQuestionnaires && reviewingQuestionnaires && reviewedQuestionnaires);
  const patchFlowIdsSet = new Set([
    ...(pendingQuestionnaires || []).map((q) => q.patch_flow_id),
    ...(reviewingQuestionnaires || []).map((q) => q.patch_flow_id),
    ...(reviewedQuestionnaires || []).map((q) => q.patch_flow_id),
  ]);
  const patchFlowIds = Array.from(patchFlowIdsSet);
  patchFlowIds.sort();
  const patchFlowIdsString = patchFlowIds.join(',');
  const {
    data: patchFlows,
    isLoading: isLoadingPatchFlows,
  } = useQuery(getPatchFlowsByIdsQueryKey(patchFlowIdsString), async () => {
    return await get<PatchFlow[]>('/patch-flows', {ids: patchFlowIdsString});
  }, {
    // TODO: Set state in `onSuccess` AND use a dataloader type of mechanism to reduce calls by IDs.
    enabled: hasAllQuestionnaires,
  });

  const patientIdsSet = new Set((patchFlows || []).map((p) => p.patient_id));
  const patientIds = Array.from(patientIdsSet);
  patientIds.sort();
  const patientIdsString = patientIds.join(',');
  const {
    data: patients,
    isLoading: isLoadingPatients,
  } = useQuery(getPatientBasicsByIdsQueryKey(patientIdsString), async () => {
    return await get<PatientBasic[]>('/patient-basics', {ids: patientIdsString});
  }, {
    // TODO: Set state in `onSuccess` AND use a dataloader type of mechanism to reduce calls by IDs.
    enabled: Boolean(patchFlows),
  });

  const isLoading = (
    isLoadingPendingQuestionnaires ||
    isLoadingReviewingQuestionnaires ||
    isLoadingReviewedQuestionnaires ||
    isLoadingPatchFlows ||
    isLoadingPatients
  );

  if (isLoading) {
    return <Loading />;
  }

  const isAnyQueryResultUndefined = (
    !pendingQuestionnaires ||
    !reviewingQuestionnaires ||
    !reviewedQuestionnaires ||
    !patchFlows ||
    !patients
  );
  if (isAnyQueryResultUndefined) {
    // TODO: error...
    return <Loading />;
  }

  // Combine questionnaires with their relevant patient.
  // Then send those pairs into the components below.
  const patientsById = new Map(patients.map((p) => [p.id, p]));
  const patientsByPatchFlowIdArr: [string, PatientBasic][] = patchFlows.filter((pf) => patientsById.has(pf.patient_id))
    .map((pf) => [pf.id, patientsById.get(pf.patient_id)!]);
  const patientsByPatchFlowId = new Map(patientsByPatchFlowIdArr);
  const getQuestionnaireAndPatient = (questionnaire: Questionnaire) => {
    return {
      questionnaire,
      patient: patientsByPatchFlowId.get(questionnaire.patch_flow_id),
    };
  };
  const reviewingQuestionnairesWithPatient = reviewingQuestionnaires.map(getQuestionnaireAndPatient);
  const pendingQuestionnairesWithPatient = pendingQuestionnaires.map(getQuestionnaireAndPatient);
  const reviewedQuestionnairesWithPatient = reviewedQuestionnaires.map(getQuestionnaireAndPatient);

  return (
    <div>
      <Row className="justify-content-center pt-2 pb-2">
        <Col sm="12" lg="10">
          <h2>My Questionnaires under Review</h2>
          <PendingQuestionnairesTable questionnaires={ reviewingQuestionnairesWithPatient } />
        </Col>
      </Row>
      <Row className="justify-content-center pt-2 pb-2">
        <Col sm="12" lg="10">
          <h2>Questionnaires Waiting for Review</h2>
          <PendingQuestionnairesTable questionnaires={ pendingQuestionnairesWithPatient } />
        </Col>
      </Row>
      <Row className="justify-content-center pt-2 pb-2">
        <Col sm="12" lg="10">
          <h2>My Previously Reviewed Questionnaires</h2>
          <ReviewedQuestionnairesTable questionnaires={ reviewedQuestionnairesWithPatient } />
        </Col>
      </Row>
    </div>
  );
}

function CompactTable({
  headers,
  children,
}: {
  headers: string[],
  children: JSX.Element[],
}) {
  return (
    <Table hover bordered size="sm">
      <thead>
        <tr>
          {headers.map((header, idx) => {
            return <th key={ idx.toString() }>{ header }</th>;
          })}
        </tr>
      </thead>
      <tbody>
        { children }
      </tbody>
    </Table>
  )
}

interface QuestionnaireAndPatient {
  questionnaire: Questionnaire,
  patient?: PatientBasic,
};

function PendingQuestionnairesTable({
  questionnaires,
}: {
  questionnaires: QuestionnaireAndPatient[],
}) {
  const headers = [
    'Patient',
    'Submitted Date',
    'Submitted Time',
    'Hours Outstanding',
    'Review',
  ];
  return (
    <CompactTable headers={ headers }>
      {questionnaires.map(({questionnaire, patient}) => {
        return <PendingQuestionnaireRow key={ questionnaire.id } questionnaire={ questionnaire } patient={ patient } />
      })}
    </CompactTable>
  );
}

const getPatientFirstAndLastName = (p: PatientBasic) => {
  const firstInitial = p.first_name.substring(0, 1);
  if (firstInitial) {
    return `${firstInitial}. ${p.last_name}`;
  } else {
    return p.last_name;
  }
};

function PendingQuestionnaireRow({
  questionnaire,
  patient,
}: {
  questionnaire: Questionnaire,
  patient?: PatientBasic,
}) {
  const submittedAt = questionnaire.submitted_at;
  const momentSubmittedAt = submittedAt ? moment(submittedAt) : null;
  const submittedAtDate = momentSubmittedAt ? momentSubmittedAt.format('MMM D, YYYY') : '';
  const submittedAtTime = momentSubmittedAt ? momentSubmittedAt.format('h:mma') : '';
  const hoursOutstanding = momentSubmittedAt ? moment.duration(moment().diff(momentSubmittedAt)).as('hours').toFixed(0) : '';
  const patientFirstAndLastName = patient ? getPatientFirstAndLastName(patient) : '';
  return (
    <tr>
      <td>{ patientFirstAndLastName }</td>
      <td>{ submittedAtDate } </td>
      <td>{ submittedAtTime } </td>
      <td>{ hoursOutstanding }</td>
      <td><Link to={ `/questionnaires/${questionnaire.id}` }>Review</Link></td>
    </tr>
  );
}

function ReviewedQuestionnairesTable({
  questionnaires,
}: {
  questionnaires: QuestionnaireAndPatient[],
}) {
  const headers = [
    'Patient',
    'Reviewed Date',
    'Reviewed Time',
    'Status',
    'View',
  ];
  return (
    <CompactTable headers={ headers }>
      {questionnaires.map(({questionnaire, patient}) => {
        return <ReviewedQuestionnaireRow key={ questionnaire.id } questionnaire={ questionnaire } patient={ patient } />
      })}
    </CompactTable>
  );
}

function ReviewedQuestionnaireRow({
  questionnaire,
  patient,
}: {
  questionnaire: Questionnaire,
  patient?: PatientBasic,
}) {
  const reviewedAt = questionnaire.reviewed_at;
  const momentReviewedAt = reviewedAt ? moment(reviewedAt) : null;
  const reviewedAtDate = momentReviewedAt ? momentReviewedAt.format('MMM D, YYYY') : '';
  const reviewedAtTime = momentReviewedAt ? momentReviewedAt.format('h:mma') : '';
  const getReviewStatusString = (reviewStatus: QuestionnaireReviewStatus | null) => {
    switch (reviewStatus) {
      case 'approved':
        return 'Approved';
      case 'denied':
        return 'Denied';
      default:
        return '';
    }
  };
  const reviewStatusString = getReviewStatusString(questionnaire.review_status);
  const patientFirstAndLastName = patient ? getPatientFirstAndLastName(patient) : '';
  return (
    <tr>
      <td>{ patientFirstAndLastName }</td>
      <td>{ reviewedAtDate } </td>
      <td>{ reviewedAtTime } </td>
      <td>{ reviewStatusString }</td>
      <td><Link to={ `/questionnaires/${questionnaire.id}` }>View</Link></td>
    </tr>
  );
}

export default QuestionnairesRoute;