export const DEFAULT_VIDEO_CONSTRAINTS: MediaTrackConstraintSet = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'EvmeVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'EvmeVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'EvmeVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'EvmeVideoApp-selectedBackgroundSettings';
