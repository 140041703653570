// TODO: Dedupe with `admin-webapp/src/utils/time_zones.ts`.

// TODO: We should do something to minimize the size of the data we pull:
// Ref: https://momentjs.com/timezone/docs/#/use-it/webpack/
// Unfortunately we don't have access to the underlying webpack config with create-react-app,
// so we might want to consider forking (https://create-react-app.dev/docs/alternatives-to-ejecting)
// (rather than ejecting) so we can update the webpack config.
// For now we can use code-splitting...
// import moment from 'moment-timezone';
import moment from 'moment';

import ProviderTimeZone from 'webapp-common/types/ProviderTimeZone';
// import HourOfDay from 'webapp-common/types/HourOfDay';

// `day` is really `NaiveDate` (`YYYY-MM-DD`)
// `time` is really `NaiveTime` (`hh:mm:ss`)
const getDateFromNaiveDateAndTimeAndTimeZone = async (day: string, time: string, tz: ProviderTimeZone): Promise<Date> => {
  await import('moment-timezone');
  const d = moment.tz(`${day} ${time}`, tz);
  return d.toDate();
};

export {
  getDateFromNaiveDateAndTimeAndTimeZone,
};