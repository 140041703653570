import { FormEventHandler, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Navigate } from 'react-router-dom';

function JoinVideoRoute() {
  const [roomName, setRoomName] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submit: FormEventHandler = (event) => {
    event.preventDefault();
    if (roomName) {
      setIsSubmitted(true);
    }
  };

  if (isSubmitted) {
    const linkName = `/video?room=${encodeURIComponent(roomName)}`;
    return <Navigate to={linkName} />
  }

  return (
    <Row className="justify-content-center mt-5">
      <Col sm="6" lg="4">
        <Alert variant="danger">
          This page is intended for <em>development only</em>!
        </Alert>
        <Form className="mb-3" onSubmit={ submit }>
          <Form.Group className="mb-3" controlId="room">
            <Form.Label>Room</Form.Label>
            <Form.Control type="text" placeholder="Room" value={ roomName } onChange={(e) => setRoomName(e.target.value)}/>
          </Form.Group>
          <Button type="submit" className="w-100">
            Join
          </Button>
        </Form>
      </Col>
    </Row>
  );
}

export default JoinVideoRoute;