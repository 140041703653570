import Lightbox from 'react-awesome-lightbox';
// You need to import the CSS only once
import 'react-awesome-lightbox/build/style.css';

interface LightboxPhotoImage {
  url: string,
  title: string,
}

interface Props {
  images: LightboxPhotoImage[],
  currentIndex: number,
  onClose: () => void,
}

function LightboxPhotos({
  images,
  currentIndex,
  onClose,
}: Props) {

  return (
    <Lightbox
      images={ images }
      startIndex={ currentIndex }
      onClose={ onClose }
    />
  );
}

export default LightboxPhotos;