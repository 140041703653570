import { useSearchParams } from "react-router-dom";


function PatientsRoute() {
  const [searchParams] = useSearchParams();

  const query = searchParams.get('q');

  return (
    <div>
      <h1>Patients</h1>
      <p>query = {query}</p>
    </div>
  );
}

export default PatientsRoute;