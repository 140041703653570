type PatchAllergenReadingScore =
  '-' |
  '?+' |
  'IR' |
  '1+' |
  '2++' |
  '3+++';

// NOTE: This is in order of increasing reaction.
const PATCH_ALLERGEN_READING_SCORES: PatchAllergenReadingScore[] = [
  '-',
  '?+',
  'IR',
  '1+',
  '2++',
  '3+++',
];

const getPatchAllergenReadingScoreLabel = (score: PatchAllergenReadingScore): string => {
  switch (score) {
    case '-':
      return 'Negative';
    case '?+':
      return 'Doubtful';
    case 'IR':
      return 'Irritant';
    case '1+':
      return 'Weak';
    case '2++':
      return 'Strong';
    case '3+++':
      return 'Extreme';
  }
}

export default PatchAllergenReadingScore;
export {
  PATCH_ALLERGEN_READING_SCORES,
  getPatchAllergenReadingScoreLabel,
};