import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import { useAuth } from 'webapp-common/auth/AuthProvider';
import { get } from 'webapp-common/api/RestApi';
import PatchFlowVisit from 'webapp-common/models/PatchFlowVisit';
import PatchFlowVisitType from 'webapp-common/models/PatchFlowVisitType';
import ProviderScheduleSlot from 'webapp-common/models/ProviderScheduleSlot';
import NaiveDate from 'webapp-common/types/NaiveDate';
import NaiveTime from 'webapp-common/types/NaiveTime';
import DayOfWeek, { DAYS_OF_WEEK } from 'webapp-common/types/DayOfWeek';

import {
  getPatchFlowVisitByIdQueryKey,
  getPatchFlowVisitsByProviderIdQueryKey,
  getProviderScheduleSlotsByProviderIdQueryKey,
} from '../utils/query-keys';
import Loading from '../components/Loading';
import AppointmentsCalendar from '../components/AppointmentsCalendar';
import { Link } from 'react-router-dom';

const compareSlots = (slotA: ProviderScheduleSlot, slotB: ProviderScheduleSlot): number => {
  const slotAStr = `${slotA.start_date}-${slotA.start_time}`;
  const slotBStr = `${slotB.start_date}-${slotB.start_time}`;
  if (slotAStr < slotBStr) {
    return -1;
  } else if (slotAStr > slotBStr) {
    return 1;
  }
  return 0;
};

interface ParsedProviderScheduleSlot {
  id: string,
  provider_id: string,
  start_time: NaiveTime,
  duration: number,
  start_date: NaiveDate,
  end_date: NaiveDate | null,
  placement_day: DayOfWeek,
  deleted_at: Date | null,
}

const getParsedProviderScheduleSlot = (slot: ProviderScheduleSlot): ParsedProviderScheduleSlot => {
  return {
    id: slot.id,
    provider_id: slot.provider_id,
    start_time: NaiveTime.fromString(slot.start_time),
    duration: slot.duration,
    start_date: NaiveDate.fromString(slot.start_date),
    end_date: slot.end_date ? NaiveDate.fromString(slot.end_date) : null,
    placement_day: slot.placement_day,
    deleted_at: slot.deleted_at,
  };
};

// NaiveTime: HH:MM:SS
const renderHourFromNaiveTime = (naiveTime: NaiveTime): string => {
  const clockHours = naiveTime.getClockHours();
  const ampm = naiveTime.isAm() ? 'a' : 'p';
  return `${clockHours}${ampm}`;
}

const renderTimeSlot = (startTime: NaiveTime, duration: number): string => {
  const endTime = startTime.addMinutes(duration);
  const startTimeStr = renderHourFromNaiveTime(startTime);
  const endTimeStr = renderHourFromNaiveTime(endTime);
  return `${startTimeStr} - ${endTimeStr} PT`;
}

const getDaysTriplet = (start: DayOfWeek): DayOfWeek[] => {
  const dayIndex = DAYS_OF_WEEK.indexOf(start);
  return [
    start,
    DAYS_OF_WEEK[(dayIndex + 2) % DAYS_OF_WEEK.length],
    DAYS_OF_WEEK[(dayIndex + 4) % DAYS_OF_WEEK.length],
  ];
}

const renderDaysTriplet = (start: DayOfWeek, sep: string = ', '): string => {
  const triplet = getDaysTriplet(start);
  return triplet.join(sep);
}

const getTitleFromVisitType = (visitType: PatchFlowVisitType) => {
  if (visitType === 'placement') {
    return 'Placement';
  } else if (visitType === 'reading-one') {
    return 'Reading #1';
  } else if (visitType === 'reading-two') {
    return 'Reading #2';
  } else {
    return '???';
  }
}

const getDateString = (visitStartTimeStr: string) => {
  const startTime = new Date(visitStartTimeStr);
  const startMoment = moment(startTime);
  return startMoment.format('ddd, MMM DD');
};

const getTimeString = (visitStartTimeStr: string) => {
  const startTime = new Date(visitStartTimeStr);
  const startMoment = moment(startTime);
  const endMoment = moment(startTime).add(10, 'minutes');
  const startTimeString = startMoment.format('h:mm a');
  const endTimeString = endMoment.format('h:mm a');
  return `${startTimeString} - ${endTimeString}`;
};

function AppointmentsRoute() {
  const auth = useAuth();
  const providerId = auth.user?.id as string;
  const queryClient = useQueryClient();

  const [calendarSelectedVisit, setCalendarSelectedVisit] = useState<PatchFlowVisit>();

  // This is to be consistent with the `week` view of react-big-calendar
  const defaultCalendarStartDate = moment().startOf('week').toDate();
  const defaultCalendarEndDate = moment().endOf('week').toDate();
  const [calendarDateRange, setCalendarDateRange] = useState({
    start: defaultCalendarStartDate,
    end: defaultCalendarEndDate,
  });
  const upcomingDateRange = {
    start: moment().startOf('day').toDate(),
    end: moment().add(7, 'days').endOf('day').toDate(),
  };

  const {
     data: providerScheduleSlots,
  } = useQuery(getProviderScheduleSlotsByProviderIdQueryKey(providerId), async () => {
    return await get<ProviderScheduleSlot[]>('/provider-schedule-slots', {provider_id: providerId});
  });

  const cachePatchFlowVisits = (visits: PatchFlowVisit[]) => {
    visits.forEach((v) => {
      queryClient.setQueryData(getPatchFlowVisitByIdQueryKey(v.id), v);
    });
  };

  const {
    data: upcomingPatchFlowVisits,
  } = useQuery(getPatchFlowVisitsByProviderIdQueryKey(providerId, upcomingDateRange), async () => {
    return await get<PatchFlowVisit[]>('/patch-flow-visits', {
      provider_id: providerId,
      after: upcomingDateRange.start.toISOString(),
      before: upcomingDateRange.end.toISOString(),
    });
  }, {
    onSuccess: cachePatchFlowVisits,
  });

  const {
    data: calendarPatchFlowVisits,
    isFetching: isFetchingCalendarPatchFlowVisits,
  } = useQuery(getPatchFlowVisitsByProviderIdQueryKey(providerId, calendarDateRange), async () => {
    return await get<PatchFlowVisit[]>('/patch-flow-visits', {
      provider_id: providerId,
      after: calendarDateRange.start.toISOString(),
      before: calendarDateRange.end.toISOString(),
    });
  }, {
    onSuccess: cachePatchFlowVisits,
  });

  if (!providerScheduleSlots || !upcomingPatchFlowVisits) {
    return <Loading />;
  }

  const onChangeDateRange = (calendarDateRange: {start: Date, end: Date}) => {
    // For caching, use day boundaries...
    // e.g. for `agenda` which uses `now and would otherwise not reuse the cache.
    const startDate = moment(calendarDateRange.start).startOf('day').toDate();
    const endDate = moment(calendarDateRange.end).endOf('day').toDate();
    setCalendarDateRange({start: startDate, end: endDate});
  }

  const activeSlots = providerScheduleSlots.filter((slot) => !slot.deleted_at);
  activeSlots.sort(compareSlots);
  const parsedSlots = activeSlots.map(getParsedProviderScheduleSlot);

  const nowTime = new Date().getTime();
  const upcomingAppointments = upcomingPatchFlowVisits.map((v) => {
    const startTime = new Date(v.start_time);
    const startMoment = moment(startTime);
    const endMoment = moment(startTime).add(10, 'minutes');
    const endTime = endMoment.toDate();
    const startTimeString = startMoment.format('h:mm a');
    const endTimeString = endMoment.format('h:mm a');
    return {
      id: v.id,
      start: startTime,
      end: endTime,
      dateString: startMoment.format('ddd, MMM DD'),
      timeString: `${startTimeString} - ${endTimeString}`,
      visitTypeString: getTitleFromVisitType(v.visit_type),
    };
  }).filter((app) => app.end.getTime() > nowTime)
  upcomingAppointments.sort((a, b) => a.start.getTime() - b.start.getTime());

  const onSelectVisit = (visit: PatchFlowVisit) => {
    setCalendarSelectedVisit(visit);
  };

  const modalBody = calendarSelectedVisit ? (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Appointment: { getTitleFromVisitType(calendarSelectedVisit.visit_type) }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{ getDateString(calendarSelectedVisit.start_time) }</div>
        <div>{ getTimeString(calendarSelectedVisit.start_time) }</div>
        <Link to={`/appointments/${calendarSelectedVisit.id}`}>view</Link>
      </Modal.Body>
    </>
  ) : null;

  return (
    <div>
      <Row className="justify-content-center pt-2 pb-2">
        <Col sm="12" lg="10">
          <h2>Upcoming Appointments</h2>
          <Table bordered>
            <thead>
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Visit Type</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {upcomingAppointments.map((app) => {
                return (
                  <tr key={app.id}>
                    <td>{ app.dateString }</td>
                    <td>{ app.timeString }</td>
                    <td>{ app.visitTypeString }</td>
                    <td><Link to={ `/appointments/${app.id}` }>view</Link></td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="justify-content-center pt-2 pb-2">
        <Col sm="12" lg="10">
          <h2>Appointments Calendar</h2>
          {/* Idea from https://github.com/jquense/react-big-calendar/issues/773#issuecomment-461744739 */}
          <div className={ isFetchingCalendarPatchFlowVisits ? 'opacity-50' : undefined}>
            <AppointmentsCalendar
              slots={activeSlots}
              visits={calendarPatchFlowVisits || []}
              onSelectVisit={ onSelectVisit }
              onChangeDateRange={ onChangeDateRange }
            />
          </div>
        </Col>
      </Row>
      <Modal show={Boolean(calendarSelectedVisit)} onHide={() => setCalendarSelectedVisit(undefined)}>
        { modalBody }
      </Modal>
      <Row className="justify-content-center pt-2 pb-2">
        <Col sm="12" lg="10">
          <h2>My Scheduled Slots</h2>
          <Table bordered>
            <thead>
              <tr>
                <th>Start Date</th>
                <th>Day Sequence</th>
                <th>Time Slot</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              {parsedSlots.map((parsedSlot) => {
                return (
                  <tr key={parsedSlot.id}>
                    <td>{ parsedSlot.start_date.asString() }</td>
                    <td>{ renderDaysTriplet(parsedSlot.placement_day) }</td>
                    <td>{ renderTimeSlot(parsedSlot.start_time, parsedSlot.duration) }</td>
                    <td>{ parsedSlot.end_date ? parsedSlot.end_date.asString() : '' }</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

export default AppointmentsRoute;