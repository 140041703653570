import { FormEventHandler, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import QuestionnaireReviewStatus from 'webapp-common/models/QuestionnaireReviewStatus';

interface QuestionnaireReviewFormProps {
  reviewStatus: QuestionnaireReviewStatus | null,
  reviewNote: string | null,
  saveReview: (reviewStatus: QuestionnaireReviewStatus | null, reviewNote: string | null) => void,
  isSavingReview: boolean,
  submitReview: () => void,
  isSubmittingReview: boolean,
  yieldReview: () => void,
  isYieldingReview: boolean,
}

export default function QuestionnaireReviewForm({
  reviewStatus,
  reviewNote,
  saveReview,
  isSavingReview,
  submitReview,
  isSubmittingReview,
  yieldReview,
  isYieldingReview,
}: QuestionnaireReviewFormProps) {
  const [formReviewStatus, setFormReviewStatus] = useState(reviewStatus);
  const [formReviewNote, setFormReviewNote] = useState(reviewNote);
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);

  const isFormChanged = reviewStatus !== formReviewStatus || reviewNote !== formReviewNote;
  const isSaveDisabled = isSavingReview || !isFormChanged;
  const isSubmitDisabled = reviewStatus === null || isFormChanged || (reviewStatus === QuestionnaireReviewStatus.Denied && !reviewNote);

  const updateFormReviewStatus = (value: string) => {
    if (value === '') {
      setFormReviewStatus(null);
    } else {
      setFormReviewStatus(value as QuestionnaireReviewStatus);
    }
  }
  const updateFormReviewNote = (value: string) => {
    const val = value || null;
    setFormReviewNote(val);
  }

  const saveFormReview: FormEventHandler = (event) => {
    event.preventDefault();
    saveReview(formReviewStatus, formReviewNote);
  }


  const confirmSubmitReview = () => {
    setShowSubmitConfirmation(true);
  }
  const closeSubmitConfirmation = () => {
    setShowSubmitConfirmation(false);
  }

  const submitFormReview = () => {
    submitReview();
  }

  const yieldFormReview = async () => {
    yieldReview();
  }

  return (
    <div>
      <Form onSubmit={saveFormReview} className="mb-3">
        <Form.Group className="mb-3">
          <Form.Label>Approve / Deny</Form.Label>
          <Form.Select aria-label="Approve or deny" value={formReviewStatus || ''} onChange={(e) => updateFormReviewStatus(e.target.value)}>
            <option value={''}>Approve / Deny</option>
            <option value={QuestionnaireReviewStatus.Approved}>Approve</option>
            <option value={QuestionnaireReviewStatus.Denied}>Deny</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="review-notes">
          <Form.Label>Message for patient</Form.Label>
          <Form.Control as="textarea" rows={3} value={formReviewNote || ''} onChange={(e) => updateFormReviewNote(e.target.value)} />
          <Form.Text>
            <div>If denied, you must provide a message to the patient explaining why they have been denied.</div>
            <div>If approved, there is no need to provide a messsage.</div>
          </Form.Text>
        </Form.Group>
        <Button type="submit" disabled={isSaveDisabled} className="w-100">
          { isSavingReview ? 'Saving...' : 'Save' }
        </Button>
      </Form>
      <Button onClick={confirmSubmitReview} disabled={isSubmitDisabled} className="w-100">Submit</Button>
      <hr/>
      <div className="mb-3">
        <Button variant="danger" onClick={yieldFormReview} disabled={isYieldingReview} className="w-100">
          { isYieldingReview ? 'Yielding Review...' : 'Yield Review' }
        </Button>
      </div>
      <Modal show={showSubmitConfirmation} onHide={closeSubmitConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to submit your review? You cannot edit the review after submission.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeSubmitConfirmation} disabled={isSubmittingReview}>Close</Button>
          <Button variant="primary" onClick={submitFormReview} disabled={isSubmittingReview}>
            { isSubmittingReview ? 'Submitting...' : 'Submit' }
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}