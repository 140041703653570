import Table from 'react-bootstrap/Table';

import QuestionnaireMedicationsTable from './QuestionnaireMedicationsTable';

import Questionnaire from 'webapp-common/models/Questionnaire';
import QuestionnaireMedication from 'webapp-common/models/QuestionnaireMedication';

const booleanToYesNo = (val: boolean | null): string => {
  if (val === true) {
    return 'Yes';
  } else if (val === false) {
    return 'No';
  } else {
    return '-';
  }
};

function BooleanResponseRow({
  message,
  value,
  correctValue,
}: {
  message: string,
  value: boolean | null,
  correctValue: boolean,
}) {
  const isIncorrect = value !== null && value !== correctValue;
  return (
    <tr className={ isIncorrect ? 'bg-danger bg-opacity-50' : ''}>
      <td>{ message }</td>
      <td>{ booleanToYesNo(value) }</td>
    </tr>
  );
}

export default function QuestionnaireReviewTable({
  questionnaire,
  medications,
}: {
  questionnaire: Questionnaire,
  medications: QuestionnaireMedication[],
}) {
  const booleanToYesNo = (val: boolean | null): string => {
    if (val === true) {
      return 'Yes';
    } else if (val === false) {
      return 'No';
    } else {
      return '-';
    }
  };

  const noQuestions = [{
    message: 'Are you pregnant or breastfeeding?',
    value: questionnaire.is_pregnant_or_breastfeeding,
  }, {
    message: 'Have you put any steroid creams or other immunosuppressant topical creams [Elidel (pimecrolimus), Protopic (tacrolimus), Eucrisa (crisaborole)] on your upper arm skin during the last 7 days?',
    value: questionnaire.has_used_topical_creams,
  }, {
    message: 'Have you taken any oral or injectable treatment with steroids in the last 30 days? Have you taken any other medications that suppress your immune system in the last 30 days?',
    value: questionnaire.has_used_steroids,
  }, {
    message: 'Have you had any treatment with ultraviolet (UV) light (including tanning) during the previous 3 weeks?',
    value: questionnaire.has_done_uv_treatment,
  }, {
    message: 'Do you have any active rash on both of your upper arms (between your elbow and your shoulder) where the patch will be placed?',
    value: questionnaire.has_active_arm_rash,
  }, {
    message: 'Do you have any tattoos or permanent markings on BOTH of your upper arms which may affect being able to read the patch?',
    value: questionnaire.has_arm_markings,
  }];

  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {noQuestions.map(({ message, value }, index) => {
          return <BooleanResponseRow key={ index.toString() } message={ message } value={ value } correctValue={ false } />
        })}
        <tr>
          <td colSpan={2}>
            <p className="mb-0">Medications:</p>
            <QuestionnaireMedicationsTable medications={ medications } />
          </td>
        </tr>
        <BooleanResponseRow
          message="The timing is strict in order to produce accurate test results. Once appointments are made, they cannot be rescheduled and the test is non-refundable. Are you able to commit to the 3 virtual video appointments [lasting 5 to 10 minutes] scheduled by you?"
          value={ questionnaire.can_commit_to_schedule }
          correctValue={ true }
        />
      </tbody>
    </Table>
  );
};