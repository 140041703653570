import moment from 'moment';

import QuestionnaireReviewStatus from 'webapp-common/models/QuestionnaireReviewStatus';

export default function QuestionnaireReviewResult({
  reviewedAt,
  reviewStatus,
  reviewNote,
}: {
  reviewedAt: Date | null,
  reviewStatus: QuestionnaireReviewStatus | null,
  reviewNote: string | null,
}) {
  const momentReviewedAt = reviewedAt ? moment(reviewedAt) : null;
  const reviewedAtDisplay = momentReviewedAt ? momentReviewedAt.format('MMM D, YYYY [at] h:mma') : '';
  const reviewStatusText = reviewStatus === QuestionnaireReviewStatus.Approved ? 'Approved' : 'Denied';
  return (
    <div>
      <p><strong>Reviewed at:</strong> {reviewedAtDisplay}</p>
      <p><strong>Status:</strong> { reviewStatusText }</p>
      <p><strong>Message</strong></p>
      <p><em>{ reviewNote }</em></p>
    </div>
  )

}