import { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Navigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { get, patch, post } from 'webapp-common/api/RestApi';
import { useAuth } from 'webapp-common/auth/AuthProvider';

import Questionnaire from 'webapp-common/models/Questionnaire';
import QuestionnaireMedication from 'webapp-common/models/QuestionnaireMedication';
import QuestionnaireReviewStatus from 'webapp-common/models/QuestionnaireReviewStatus';
import QuestionnaireReviewPatchUpdate from 'webapp-common/models/QuestionnaireReviewPatchUpdate';

import Loading from '../components/Loading';
import QuestionnaireReviewTable from '../components/questionnaire/QuestionnaireReviewTable';
import QuestionnaireReviewForm from '../components/questionnaire/QuestionnaireReviewForm';
import QuestionnaireReviewResult from '../components/questionnaire/QuestionnaireReviewResult';

import {
  getPendingQuestionnairesByProviderIdQueryKey,
  getQuestionnaireByIdQueryKey,
  getQuestionnaireMedicationsByQuestionnaireIdQueryKey,
  getReviewedQuestionnairesByProviderIdQueryKey,
  getReviewingQuestionnairesByProviderIdQueryKey,
} from '../utils/query-keys';


const claimQuestionnaire = async ({
  questionnaireId,
  providerId,
}: {
  questionnaireId: string | undefined,
  providerId: string,
}) => {
  if (!questionnaireId) {
    throw new Error('Missing questionnaire ID');
  }
  return await post<Questionnaire>(`/questionnaires/${questionnaireId}/claim-review`, {
    reviewer_id: providerId,
  });
};

const yieldReviewOfQuestionnaire = async (questionnaireId: string | undefined) => {
  if (!questionnaireId) {
    throw new Error('Missing questionnaire ID');
  }
  return await post<Questionnaire>(`/questionnaires/${questionnaireId}/claim-review`, {
    reviewer_id: null,
  });
};

const reviewPatchUpdate = async ({
  questionnaireId,
  update,
}: {
  questionnaireId: string | undefined,
  update: QuestionnaireReviewPatchUpdate,
}) => {
  if (!questionnaireId) {
    throw new Error('Missing questionnaire ID');
  }
  return await patch<Questionnaire>(`/questionnaires/${questionnaireId}/review`, update);
}

const submitQuestionnaireReview = async (questionnaireId: string | undefined) => {
  if (!questionnaireId) {
    throw new Error('Missing questionnaire ID');
  }
  return await post<Questionnaire>(`/questionnaires/${questionnaireId}/review`);
}

function QuestionnaireRoute() {
  const { questionnaireId } = useParams();
  const auth = useAuth();
  const queryClient = useQueryClient();

  // Cannot actually be `undefined` since we would have redirected.
  const providerId = auth.user?.id as string;

  const {
    data: questionnaire,
    isLoading: isLoadingQuestionnaire,
  } = useQuery(getQuestionnaireByIdQueryKey(questionnaireId), async () => {
    if (!questionnaireId) {
      throw new Error('Missing questionnaire ID');
    }
    return get<Questionnaire>(`/questionnaires/${questionnaireId}`);
  });

  const {
    data: medications,
    isLoading: isLoadingMedications,
  } = useQuery(getQuestionnaireMedicationsByQuestionnaireIdQueryKey(questionnaireId), async () => {
    if (!questionnaireId) {
      throw new Error('Missing questionnaire ID');
    }
    return get<QuestionnaireMedication[]>(`/questionnaires/${questionnaireId}/medications`);
  });

  const setQuestionnaire = (q: Questionnaire) => {
    queryClient.setQueryData(getQuestionnaireByIdQueryKey(q.id), q);
    // Invalidate the pending/reviewing/reviewed questionnaires queries...
    queryClient.removeQueries(getPendingQuestionnairesByProviderIdQueryKey(providerId));
    queryClient.removeQueries(getReviewingQuestionnairesByProviderIdQueryKey(providerId));
    queryClient.removeQueries(getReviewedQuestionnairesByProviderIdQueryKey(providerId));
  };

  const claimQuestionnaireMutation = useMutation(claimQuestionnaire, {
    onSuccess: setQuestionnaire,
    mutationKey: 'claim-questionnaire',
  });
  const yieldReviewMutation = useMutation(yieldReviewOfQuestionnaire, {
    onSuccess: setQuestionnaire,
  });
  const updateReviewMutation = useMutation(reviewPatchUpdate, {
    onSuccess: setQuestionnaire,
  });
  const submitReviewMutation = useMutation(submitQuestionnaireReview, {
    onSuccess: setQuestionnaire,
  });

  useEffect(() => {
    if (questionnaire && (questionnaire.reviewer_id === null) && claimQuestionnaireMutation.isIdle && !yieldReviewMutation.isSuccess) {
      const numMutating = queryClient.isMutating({
        mutationKey: 'claim-questionnaire',
        predicate(mutation) {
          return mutation.options.variables.questionnaireId === questionnaire.id;
        },
      });
      const isMutatingAlready = numMutating > 0;
      // Need this extra check because of React 18's mount > unmount > mount
      // pattern with strict mode (https://github.com/tannerlinsley/react-query/issues/3633).
      // Ref: https://reactjs.org/docs/strict-mode.html#ensuring-reusable-state
      if (!isMutatingAlready) {
        claimQuestionnaireMutation.mutate({providerId, questionnaireId});
      }
    }
  }, [questionnaire, claimQuestionnaireMutation.isIdle, yieldReviewMutation.isSuccess]);

  const isLoading = isLoadingQuestionnaire || isLoadingMedications;

  if (isLoading) {
    return <Loading />;
  }

  if (!questionnaire || !medications) {
    // TODO: more graceful...
    return <Navigate to="/questionnaires" />;
  }

  if (yieldReviewMutation.isSuccess) {
    return <Navigate to="/questionnaires" />;
  }

  const saveReview = (reviewStatus: QuestionnaireReviewStatus | null, reviewNote: string | null) => {
    const update = {
      review_status: reviewStatus,
      review_note: reviewNote,
    };
    updateReviewMutation.mutate({questionnaireId, update});
  };

  const submitReview = () => {
    submitReviewMutation.mutate(questionnaireId);
  };

  const yieldReview = () => {
    yieldReviewMutation.mutate(questionnaireId);
  };

  const isReviewed = questionnaire.reviewed_at !== null;
  const isSavingReview = updateReviewMutation.isLoading;
  const isSubmittingReview = submitReviewMutation.isLoading;
  const isYieldingReview = yieldReviewMutation.isLoading;

  const reviewBody = isReviewed ? (
    <QuestionnaireReviewResult
      reviewedAt={questionnaire.reviewed_at}
      reviewStatus={questionnaire.review_status}
      reviewNote={questionnaire.review_note}
      />
  ) : (
    <QuestionnaireReviewForm
      reviewStatus={questionnaire.review_status}
      reviewNote={questionnaire.review_note}
      saveReview={saveReview}
      isSavingReview={isSavingReview}
      submitReview={submitReview}
      isSubmittingReview={isSubmittingReview}
      yieldReview={yieldReview}
      isYieldingReview={isYieldingReview}
      />
  );

  return (
    <Row>
      <Col lg="8">
        <h1>Questionnaire Responses</h1>
        <div>Questionnaire ID: { questionnaireId }</div>
        <QuestionnaireReviewTable questionnaire={ questionnaire } medications={ medications } />
      </Col>
      <Col lg="4">
        <h1>Review</h1>
        <hr/>
        { reviewBody }
      </Col>
    </Row>
  );
};


export default QuestionnaireRoute;