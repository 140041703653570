import { FormEventHandler, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useMutation } from 'react-query';

import { useAuth, AuthContextType } from 'webapp-common/auth/AuthProvider';


const doLogin = async ({
  auth,
  username,
  password
}: {
  auth: AuthContextType,
  username: string, 
  password: string,
}) => {
  await auth.signin(username, password, 'provider');
};

function LoginRoute() {
  const location = useLocation();

  const auth = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const loginMutation = useMutation(doLogin);

  const state = location.state as {from?: {pathname: string}};
  const from = state?.from?.pathname || '/';

  const submitLogin: FormEventHandler = async (event) => {
    event.preventDefault();
    loginMutation.mutate({auth, username, password});
  };

  const errorMessage = loginMutation.error ? (loginMutation.error as Error).message : null;

  if (loginMutation.isSuccess) {
    return <Navigate to={ from } replace />;
  }

  const isSignInDisabled = loginMutation.isLoading || !username || !password;

  return (
    <div>
      <Row className="justify-content-center mt-5">
        <Col sm="6" lg="4">
          {errorMessage && 
            <Alert variant="danger">
              {errorMessage}
            </Alert>
          }
          <Form className="mb-3" onSubmit={submitLogin}>
            <Form.Group className="mb-3" controlId="loginEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Email" onChange={(e) => setUsername(e.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="loginPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
            </Form.Group>
            <Button disabled={isSignInDisabled} type="submit" className="w-100">
              {loginMutation.isLoading ? 'Signing In...' : 'Sign In'}
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default LoginRoute;