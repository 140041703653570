import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { get } from 'webapp-common/api/RestApi';

import VideoApp from '../components/VideoApp';

interface AccessTokenResponse {
  token: string,
}

function VideoRoute() {
  const searchParams = useSearchParams()[0];
  const navigate = useNavigate();

  const fetchTokenMutation = useMutation(async () => {
    if (!roomName) {
      throw new Error('No room name');
    }
    const { token } = await get<AccessTokenResponse>('/dev/twilio-video-access-token', { room: roomName });
    return token;
  });

  const roomName = searchParams.get('room');
  if (!roomName) {
    return <Navigate to="/join-video" />
  }

  const onExit = () => {
    navigate('/join-video');
  };

  return (
    <VideoApp
      roomName={ roomName }
      onExit={ onExit }
      fetchToken={ fetchTokenMutation.mutateAsync }
      isFetchingToken={ fetchTokenMutation.isLoading }
    />
  );
}

export default VideoRoute;